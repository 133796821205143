:root {
  --shine-degree: 120deg;
  --shine-color: rgba(255, 255, 255, 0.2);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all 0.65s ease-in-out;
}

.new-btn {
  --newBtnBorderWidth: 3px;
  background: #1d1f20;
  position: relative;
  border-radius: 50px;
  border: 0px;
  cursor: pointer;
  color: #35ff6e;
  /* overflow: hidden; */
}

/* .new-btn::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--shine-effect);
} */

.new-btn:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--newBtnBorderWidth));
  left: calc(-1 * var(--newBtnBorderWidth));
  height: calc(100% + var(--newBtnBorderWidth) * 2);
  width: calc(100% + var(--newBtnBorderWidth) * 2);
  background: linear-gradient(60deg, #37ff69, #27dcab, #20b0f5, #5478fa, #9e31ff, #d402ff);
  border-radius: 50px;
  z-index: -1;
  background-size: 300% 300%;
}
.new-btn:hover {
  background: transparent;
  color: #fff;
}

.new-btn-play-now {
  margin-left: 10px;
  margin-right: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 150px;
  height: 55px;
  font-family: 'Philosopher';
  font-size: 20px;
  border-radius: '30px';
}

.new-btn-play-now:after {
  animation: buynowbtngradient 3s ease alternate infinite;
}

@keyframes buynowbtngradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.btn-shine-anim::before {
  animation: shine 5s ease-in-out infinite;
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  11.5044247788%,
  100% {
    left: 100%;
    transition-property: left;
  }
}
